import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Hero from '../components/Hero';
import Resource from '../components/Resource';
import HeroImage from '../images/hero/hero-classroom-resources.png';
import DigitalLessonBundle from '../components/DigitalLessonBundle';
import CareerCallout from './partials/_CareerCallout';
import { useTranslation } from 'react-i18next';

const ClassroomResourcesPage = ({ data }) => {
  const { t, i18n } = useTranslation(['classroom-resources', 'common']);

  return (
    <Layout title={t('common:classroomResources')}>
      <Hero title={t('common:classroomResources')} image={HeroImage} />
      <Section>
        <Container>
          <Row>
            <Column size={8}>
              <h2>{t('conversationStartersHeader')}</h2>
              <p>{t('conversationStartersText')}</p>
            </Column>
          </Row>
        </Container>
      </Section>
      <DigitalLessonBundle
        title={t('digitalLessonBundle')}
        resource1={
          <Resource
            img="classroom-resources-dlb-secure-network-presentation.png"
            title={t('common:secureTheNetwork')}
            audience={`${t('common:grades')} 5–9`}
            description={
              <>
                <p>{t('secureTheNetworkLargeText1')}</p>
                <p>{t('secureTheNetworkLargeText2')}</p>
              </>
            }
            actions={[
              {
                label: t('powerpointPresentation'),
                download: t('classroom-resources:secureTheNetworkDLBPPT'),
                metadata: 'PPTX 67.2 MB',
              },
            ]}
          />
        }
        resource2={
          <Resource
            img="classroom-resources-dlb-secure-network-guide.png"
            title={t('common:secureTheNetwork')}
            audience={`${t('common:grades')} 5–9`}
            description={t('secureTheNetworkSmallText')}
            actions={[
              {
                label: t('common:educatorGuide'),
                download: t('classroom-resources:secureTheNetworkDLBPDF'),
                metadata: 'PDF 849 KB',
              },
            ]}
          />
        }
      />
      <DigitalLessonBundle
        title={t('digitalLessonBundle')}
        resource1={
          <Resource
            img="classroom-resources/classroom-resources-dlb-ppt.jpg"
            title={t('common:allThingsDigital')}
            audience={`${t('common:grades')} 5–9`}
            description={
              <>
                <p>{t('allThingsDigitalLargeText1')}</p>
                <p>{t('allThingsDigitalLargeText2')}</p>
              </>
            }
            actions={[
              {
                label: t('powerpointPresentation'),
                download: t('classroom-resources:allThingsDigitalDLBPPT'),
                metadata: 'PPTX 97.9 MB',
              },
            ]}
          />
        }
        resource2={
          <Resource
            img="classroom-resources/classroom-resources-dlb-educator-guide.jpg"
            title={t('common:allThingsDigital')}
            audience={`${t('common:grades')} 5–9`}
            description={t('allThingsDigitalSmallText')}
            actions={[
              {
                label: t('common:educatorGuide'),
                download: t('classroom-resources:allThingsDigitalDLBPDF'),
                metadata: 'PDF 1.1 MB',
              },
            ]}
          />
        }
      />
      <Section className="classroom-resources__activities">
        <Container>
          <h2>{t('classroomActivitiesHeader')}</h2>
          <p className="classroom-resources__activities-intro">
            {t('classroomActivitiesText')}
          </p>
          <Row>
            <Column size={4}>
              <Resource
                tophat={t('classroomActivity')}
                img="classroom-resources/classroom-resources-card-pink.jpg"
                icon="classroom-resources/classroom-resources-icon-lesson1.svg"
                title={t('common:cryptology')}
                audience={`${t('common:grades')} 6–8`}
                description={t('cryptologyResourceText')}
                actions={[
                  {
                    label: t('lessonPlan'),
                    download: t('cryptologyResourcePdf'),
                    metadata: 'PDF 1.2 MB',
                    linkFromImg: true,
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={t('classroomActivity')}
                img="classroom-resources/classroom-resources-card-teal.jpg"
                icon="classroom-resources/classroom-resources-icon-lesson2.svg"
                title={t('common:fraudDetection')}
                audience={`${t('common:grades')} 6–8`}
                description={t('fraudDetectionResourceText')}
                actions={[
                  {
                    label: t('lessonPlan'),
                    download: t('fraudDetectionResourcePdf'),
                    metadata: 'PDF 1.3 MB',
                    linkFromImg: true,
                  },
                ]}
              />
            </Column>

            <Column size={4}>
              <Resource
                tophat={t('classroomActivity')}
                img="classroom-resources/classroom-resources-card-red.jpg"
                icon="classroom-resources/classroom-resources-icon-lesson3.svg"
                title={t('common:allThingsDigital')}
                audience={`${t('common:grades')} 6–8`}
                description={t('allThingsDigitalResourceText')}
                actions={[
                  {
                    label: t('lessonPlan'),
                    download: t('allThingsDigitalResourcePdf'),
                    metadata: 'PDF 1.2 MB',
                    linkFromImg: true,
                  },
                ]}
              />
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={t('classroomActivity')}
                img="classroom-resources/classroom-resources-card-green.jpg"
                icon="classroom-resources/classroom-resources-icon-lesson4.svg"
                title={t('common:bigData')}
                audience={`${t('common:grades')} 6–8`}
                description={t('bigDataResourceText')}
                actions={[
                  {
                    label: t('lessonPlan'),
                    download: t('bigDataResourcePdf'),
                    metadata: 'PDF 878 KB',
                    linkFromImg: true,
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={t('classroomActivity')}
                img="classroom-resources/classroom-resources-card-orange.jpg"
                icon="classroom-resources/classroom-resources-icon-lesson5.svg"
                title={t('common:aiForGood')}
                audience={`${t('common:grades')} 6–8`}
                description={t('aiSocialGoodResourceText')}
                actions={[
                  {
                    label: t('lessonPlan'),
                    download: t('aiSocialGoodResourcePdf'),
                    metadata: 'PDF 1.3 MB',
                    linkFromImg: true,
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <CareerCallout
        title={t('footerCareerCalloutHeader')}
        description={
          <p
            dangerouslySetInnerHTML={{
              __html: t('footerCareerCalloutBody'),
            }}
          />
        }
        path={`/${i18n.language}${t('footerCareerCalloutURL')}`}
        img={t('career-profiles:globalVicePresidentQuoteImage')}
        name={t('footerCareerCalloutName')}
        job={t('footerCareerCalloutJob')}
      />
    </Layout>
  );
};

export default ClassroomResourcesPage;
